import { useCallback } from 'react'
import useSWR, { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'

import { get, post } from '@/api/axios'
import { PostDetail } from '@/types/api/post'
import {
  addPostToBloomFilter,
  getBloomFilterFromLocalStorage,
  isPostInBloomFilter,
} from '@/lib/bloomFilterUtils'

export const useFeeds = () => {
  const mutation = useSWRMutation('/feed/home', async (url: string) => {
    try {
      const bloomFilter = getBloomFilterFromLocalStorage()
      const res = await post<PostDetail[]>(url, {
        clientBloomfilterJSON: JSON.stringify(bloomFilter.saveAsJSON()),
      })

      return res.data
    } catch (error) {
      console.error(`get feeds error`, error)
      throw error
    }
  })

  const markPostAsSeen = (postId: string) => {
    addPostToBloomFilter(postId)
  }

  // because we are using a bloom filter to filter out seen posts, the refetch and loadMore functions are the same
  const refetch = useCallback(() => {
    return mutation.trigger()
  }, [mutation])

  const loadMore = useCallback(() => {
    return mutation.trigger()
  }, [mutation])

  return { ...mutation, markPostAsSeen, refetch, loadMore }
}

export const usePostDetail = (postId?: string) => {
  const swr = useSWR(postId ? `/post/${postId}` : null, async (url: string) => {
    try {
      const res = await get<PostDetail>(url)
      if (!res.data) {
        throw new Error(`post not found`)
      }

      return res.data
    } catch (error) {
      console.error(`get post error`, error)
    }
  })

  const refetch = useCallback(() => {
    return mutate(postId ? `/post/${postId}` : null)
  }, [postId])

  return {
    ...swr,
    refetch,
  }
}
