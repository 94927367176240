import { BloomFilter } from 'bloom-filters'

const BLOOM_FILTER_SIZE = 45000 // Adjust based on expected number of items
const BLOOM_FILTER_FALSE_POSITIVE_RATE = 10
const BLOOM_FILTER_KEY = 'seenPostsBloomFilter'

function createBloomFilter(): BloomFilter {
  return new BloomFilter(BLOOM_FILTER_SIZE, BLOOM_FILTER_FALSE_POSITIVE_RATE)
}

export function getBloomFilterFromLocalStorage(): BloomFilter {
  const storedFilter = localStorage.getItem(BLOOM_FILTER_KEY)
  if (storedFilter) {
    return BloomFilter.fromJSON(JSON.parse(storedFilter))
  }
  return createBloomFilter()
}

export function saveBloomFilterToLocalStorage(filter: BloomFilter): void {
  localStorage.setItem(BLOOM_FILTER_KEY, JSON.stringify(filter.saveAsJSON()))
}

export function addPostToBloomFilter(postId: string): void {
  const filter = getBloomFilterFromLocalStorage()
  filter.add(postId)
  //   console.debug('Added post to bloom filter', postId)
  saveBloomFilterToLocalStorage(filter)
}

export function isPostInBloomFilter(postId: string): boolean {
  const filter = getBloomFilterFromLocalStorage()
  return filter.has(postId)
}
