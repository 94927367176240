'use client'

import React, { useCallback, useEffect, useState } from 'react'
import { useFeeds } from '@/api/usePostApi'
import PostList from './post-list/PostList'
import { useToast } from '@/hooks/useToast'
import InfiniteScroll from './InfiniteScroll'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  addFeeds,
  feedState,
  setFeeds,
  setIsInitialLoading,
} from '@/redux/reducers/feedReducer'
import { isEmpty } from 'radash'
import useAnalytics from '@/hooks/useAnalytics'
import DownloadAppButton from './DownloadAppButton'

const FeedPage = () => {
  const dispatch = useAppDispatch()
  const { feeds, isInitialLoading } = useAppSelector(feedState)
  const { capture } = useAnalytics()

  const { markPostAsSeen, loadMore, isMutating, refetch } = useFeeds()
  const [hasMore, setHasMore] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const { showToast } = useToast()

  const fetchInitialData = async () => {
    dispatch(setIsInitialLoading(true))
    setError(null)
    try {
      capture('get_feeds')
      const initialData = await refetch()
      if (initialData) {
        capture('get_feeds_result', {
          result: 'success',
          length: initialData.length,
        })
        dispatch(setFeeds(initialData))
        setHasMore(initialData.length > 0)
      } else {
        capture('get_feeds_result', {
          result: 'error',
          msg: 'no data',
        })
      }
    } catch (err) {
      capture('get_feeds_result', {
        result: 'error',
        msg: 'error',
        error: err,
      })
      console.debug('Failed to fetch posts. Please try again.')
      setError('Failed to fetch posts. Please try again.')
    } finally {
      dispatch(setIsInitialLoading(false))
    }
  }

  const handleLoadMore = async () => {
    setError(null)
    capture('get_feeds')
    try {
      const newPosts = await loadMore()
      if (newPosts) {
        capture('get_feeds_result', {
          result: 'success',
          length: newPosts.length,
        })
        dispatch(addFeeds(newPosts))
        setHasMore(newPosts.length > 0)
      } else {
        capture('get_feeds_result', {
          result: 'error',
          msg: 'no data',
        })
      }
    } catch (err) {
      capture('get_feeds_result', {
        result: 'error',
        msg: 'error',
        error: err,
      })
      showToast({
        description: 'Failed to load more posts. Please try again.',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (isEmpty(feeds)) {
      fetchInitialData()
    }
  }, [feeds])

  return (
    <div className="flex grow flex-col items-center px-3 py-4">
      <div className="w-full max-w-3xl">
        <InfiniteScroll
          isInitialLoading={isInitialLoading}
          loadMore={handleLoadMore}
          hasMore={hasMore}
          isLoadingMore={!isInitialLoading && isMutating}
          error={error}
          onRetry={fetchInitialData}
        >
          <PostList
            isInitialLoading={isInitialLoading}
            posts={feeds}
            markPostAsSeen={markPostAsSeen}
          />
        </InfiniteScroll>
      </div>

      <DownloadAppButton path={``} />
    </div>
  )
}

export default FeedPage
