import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { PostDetail } from '@/types/api/post'

interface FeedState {
  // Define your state interface here
  isInitialLoading: boolean
  feeds?: PostDetail[]
}

const initialState: FeedState = {
  // Set your initial state here
  isInitialLoading: true,
}

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    // Define your reducer actions here
    setFeeds: (state, action: PayloadAction<PostDetail[]>) => {
      state.feeds = action.payload
    },
    addFeeds: (state, action: PayloadAction<PostDetail[]>) => {
      state.feeds = state.feeds
        ? [...state.feeds, ...action.payload]
        : action.payload
    },
    clearFeeds: (state) => {
      state.feeds = []
    },
    setIsInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isInitialLoading = action.payload
    },
  },
})

export const { setFeeds, addFeeds, clearFeeds, setIsInitialLoading } =
  feedSlice.actions

export const feedState = (state: RootState) => state.feed

export default feedSlice.reducer
